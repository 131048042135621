import React, {Component} from 'react';
import {Route, Link} from 'react-router-dom';
import $ from 'jquery/dist/jquery.js';
import moment from 'moment';
import {Modal} from 'react-bootstrap';
import {ToastsContainer, ToastsStore, ToastsContainerPosition, timer} from 'react-toasts';
import Nav from './nav-dashboard-skin';
import atclogo from '../images/loader.png'
import coverKids from '../images/cover-kids.png';
import web from '../images/web.png';
import webpage from '../images/webpage.png';
import website from '../images/web-pages.png';
import editor from '../images/text-editor.png';
import html from '../images/html.png';
import quiz from '../images/quiz-icon.png';
import forwardSwitchButton from '../images/next.png';
import switchButtonDisabled from '../images/next-disabled.png';
import backwardSwitchButtonDisabled from '../images/back-disabled.png';
import SwitchButton from './switch-button';
import BackwardSwitchButton from './backward-switch-button';
import TheWeb from './learningmodules/theweb';
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderA from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import vle from '../images/vle.png'
import navigation from '../images/nav.png'
import attendance from '../images/attendance.png'
import fellows from '../images/fellows.png'
import skins from '../images/skins.png'





 class lab extends Component { 

   constructor(props) { 
    super(props);

     this.state ={ 
        height:"",
        width:"",
        skinPack:[],
        iconPack:[],
        assessmentRef:[],
        assessmentPretty:[],
        prevResourceRef:"",
        prevTopicRef:"",
        resoureceRef: "",
        topicRef:"",
        topicStatus:true,
        candidateFirstName:"",
        codeData:"",
        taskComplete:"",
        quizAnswer:"",
        userAnswer:"",
        taskByteValue:"",
       candidateSkin:"",
       candidateSkinRef:"",
       candidateAvatar:"",
       candidateAssessmentStatus:"",
       candidateClass:"",
       candidateAge:"",
       candidateSchool:"",
       candidateFullname: "",
       candidateSchoolid:"",
      candidateTopicVisual: "",
      candidateTopicDescription: "",
      candidateTopicName: "", 
       onlineCandidates:[],
       currentAssessmentTaskRef: "",
       currentAssessmentTopicRef: "",
       currentAssessmentRef: "",
       assessmentLink:"",
       schoollogo:"",
       assessmentGrade:"",
       totalTopicGrade:"",
       currentTopicGrade:"",
       newAssessmentRef:"",
        addManualModalShow: false,
        resetTopicName : "",
        resetVisual : "",
        resetTopicRef : "",
        resetResourceRef: "",
        resetModal: false
     }; 

 

 
     this.canvasHeight = this.canvasHeight.bind(this);
     this.canvasWidth = this.canvasWidth.bind(this);
     //this.timeUpdate = this.timeUpdate.bind(this);
     //this.sleepScreen = this.sleepScreen.bind(this);
     this.menuScreen = this.menuScreen.bind(this);
     this.menuClose = this.menuClose.bind(this);
     this.profileScreen = this.profileScreen.bind(this);
     this.exploreScreen = this.exploreScreen.bind(this);
     this.exploreClose = this.exploreClose.bind(this);
     this.profileClose = this.profileClose.bind(this);
    // this.wakeScreen = this.wakeScreen.bind(this);
     this.skinMenu = this.skinMenu.bind(this);
     this.profileMenu = this.profileMenu.bind(this);
     this.skinUpdate = this.skinUpdate.bind(this);
     this.byteStateUpdate = this.byteStateUpdate.bind(this);
     this.candidateByteIncrease = this.candidateByteIncrease.bind(this);
     this.candidateByteDecrease = this.candidateByteDecrease.bind(this);
     this.retrieveResourceDetails = this.retrieveResourceDetails.bind(this);
     this.switchSkin = this.switchSkin.bind(this);
     this.validateResponse = this.validateResponse.bind(this);
     this.htmlStateUpdate = this.htmlStateUpdate.bind(this);
     this.progressUpdate = this.progressUpdate.bind(this);
     this.taskStatusUpdate = this.taskStatusUpdate.bind(this);
     this.quizAnswerUpdate = this.quizAnswerUpdate.bind(this);
     this.validateQuizResponse = this.validateQuizResponse.bind(this);
     this.quizResponseUpdate = this.quizResponseUpdate.bind(this);
     this.retreiveResourceIconPack = this.retreiveResourceIconPack.bind(this);
     this.saveEditorResponse = this.saveEditorResponse.bind(this);
     this.retrieveOnlineFellowsBySchool = this.retrieveOnlineFellowsBySchool.bind(this);
     this.loaderOpen = this.loaderOpen.bind(this);
     this.loaderClose = this.loaderClose.bind(this);
     this.openFullscreen = this.openFullscreen.bind(this);
     this.editStudentProfile = this.editStudentProfile.bind(this);
     this.setAssessmentStatus = this.setAssessmentStatus.bind(this);
     this.getAssessmentReferences = this.getAssessmentReferences.bind(this);
     this.resultScreen = this.resultScreen.bind(this);
     this.resultClose = this.resultClose.bind(this);
     this.rotateScreen = this.rotateScreen.bind(this);
     this.checkScreen = this.checkScreen.bind(this);
     this.resetProgress = this.resetProgress.bind(this);
     this.confirmPortfolioSave = this.confirmPortfolioSave.bind(this);
     this.candidateProgressVerification = this.candidateProgressVerification.bind(this);
     this.validateAnalysisQuizResponse = this.validateAnalysisQuizResponse.bind(this);
     this.activateForwardArrow = this.activateForwardArrow.bind(this)

}


 
async componentDidMount(){

//get current width & height of container
   
     await  this.canvasHeight();
     await  this.canvasWidth();
     this.retreiveResourceIconPack()
     await  this.retrieveResourceDetails();
     await this.retrieveCandidateDetails();
     await this.candidateProgressVerification();
     this.setAssessmentStatus();
     this.editStudentProfile();
  

     //this.responsiveClass();
}



async candidateProgressVerification(){
  const {match:{params}} = this.props
  const data = { 
    currentTopic:params.topicRef,
    currentResource: params.resourceRef    
  }
  
  const progressCheck = await fetch('https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/verifyCandidateProgress',{ 

    method:"POST",
    headers:{ 
      "content-type" : "application/json"
     },
     body:JSON.stringify(data),
   mode:"cors",
   credentials:"include"
  
  })
  .then(response => response.json())
  .then(response => {
      if(!response){
        return console.log(false)
      }

      if(response){
        console.log(response)
console.log(`/lab/${response.currentTopic}/lessons/${response.currentResource}`)

window.location.href=`/lab/${response.currentTopic}/lessons/${response.currentResource}`
      }

})

}




canvasHeight() { 
    let availableSpaceHeight = document.getElementById('explanation').offsetHeight
     let  spaceHeight = availableSpaceHeight; 
       
    this.setState({
      height: spaceHeight.valueOf()
     }) 
}


canvasWidth() { 
     let availableSpaceWidth = document.getElementById('explanation').offsetWidth
     let spaceWidth = availableSpaceWidth 
    this.setState({
      width: spaceWidth.valueOf()
     }) 
  }


async shouldComponentUpdate(prevProps){

}

async componentDidUpdate(prevProps){
    const {match:{params}} = this.props
    //console.log(prevProps.match.params.resourceRef)
    //console.log(params.resourceRef)
    if (prevProps.match.params.resourceRef != params.resourceRef){   
        await this.retrieveResourceDetails()     
    }


    if (prevProps.match.params.topicRef != params.topicRef){          
        await this.retreiveResourceIconPack()
    }

  
         //console.log(this.state.taskComplete)
         //console.log(this.state.userAnswer)

          console.log(this.state.width)
         console.log(this.state.height)
         console.log(this.state.candidateSkin)

        

}

async componenDidRecieveProps(prevProps){


}

async openFullscreen() {
  if (document.requestFullscreen) {
    document.requestFullscreen();
  } else if (document.mozRequestFullScreen) { /* Firefox */
    document.mozRequestFullScreen();
  } else if (document.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    document.webkitRequestFullscreen();
  } else if (document.msRequestFullscreen) { /* IE/Edge */
    document.msRequestFullscreen();
  }
}


async checkScreen() {
console.log(window.screen.width)
  if(window.screen.width < 800 ){ 
    $("#labs").css({"display": "none"})
    $("#horizontal-screen").css({"display": "inline"})
  }else{

    $("#labs").css({"display": "inline"})
    $("#horizontal-screen").css({"display": "none"})

  }
}

async rotateScreen(){ 
  window.responsiveVoice.speak(`${this.state.candidateFirstName} , you need to rotate your screen, thank you!`) 
}

async menuScreen(){
let active
let menu
let profile
let explore 
let result

active = document.getElementById('active-screen');
menu =  document.getElementById('menu-screen');
profile = document.getElementById('fellows-screen');
explore = document.getElementById('explore-screen');
result = document.getElementById('result-screen');

$(active).fadeOut('fast',() => {
    $(menu).fadeIn('fast', () => {
      $(profile).fadeOut('fast', () => {
        $(explore).fadeOut('fast', () => { 
          $(result).fadeOut('fast')
        })
      })
    })
})

}

async menuClose(){

let active
let menu

active = document.getElementById('active-screen');
menu =  document.getElementById('menu-screen');

$(menu).fadeOut('fast', () => {
    $(active).fadeIn('fast')
})

}


async profileScreen(){ 
  console.log('i ran')
let active
let profile
let menu
let explore
let result

active = document.getElementById('active-screen');
profile =  document.getElementById('fellows-screen');
menu =  document.getElementById('menu-screen');
explore = document.getElementById('explore-screen');
result = document.getElementById('result-screen');



$(active).fadeOut('fast',() => {
    $(profile).fadeIn('fast', () => { 
       $(menu).fadeOut('fast', () => { 
        $(explore).fadeOut('fast', () => { 
               $(result).fadeOut('fast')
           })
       })
    })
})

}

async profileClose(){

let active
let profile


active = document.getElementById('active-screen');
profile =  document.getElementById('fellows-screen');

$(profile).fadeOut('fast',() => {
    $(active).fadeIn('fast')
})

}



async resultClose(){

  let explore
  let result
  
  
  explore = document.getElementById('explore-screen');
  result =  document.getElementById('result-screen');
  
  $(result).fadeOut('fast',() => {
      $(explore).fadeIn('fast')
  })
  
  }


async exploreScreen(){ 
console.log('i ran')
let active
let profile
let menu
let explore
let result

await this.retrieveCandidateDetails()
await this.setAssessmentStatus()

explore = document.getElementById('explore-screen')
active = document.getElementById('active-screen');
profile =  document.getElementById('fellows-screen');
menu =  document.getElementById('menu-screen');
result = document.getElementById('result-screen');

$(active).fadeOut('fast',() => {
    $(explore).fadeIn('fast', () => { 
      
       $(menu).fadeOut('fast', () => { 
         $(profile).fadeOut('fast', () => { 
          $(result).fadeOut('fast')
         })
       })
    })
})

}





async retrieveCandidateScore(assessmentRefName){ 

 const {match:{params}} = this.props
  const data = { 
    assessmentRef:assessmentRefName,
    topicRef: params.topicRef     
  }
 
  const skinUpdate = await fetch('https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/getCandidateResults', { 
    method:"POST",
    headers:{
      "content-type":"application/JSON"
    },
    body:JSON.stringify(data),
    mode:"cors",
    credentials:'include' 
  })

   .then(response => response.json())
   .then(response => {
     console.log(response)
    this.setState({ 
        assessmentGrade: response.assessmentGrade,
        totalTopicGrade: response.totalTopicGrade,
        currentTopicGrade: response.currentTopicGrade,
        newAssessmentRef: response.newAssessmentRef
    })
   })
   console.log(this.state.assessmentGrade)

   console.log(this.state.totalTopicGrade)

   console.log(this.state.newAssessmentRef)
   


}



async resultScreen(event){ 
  console.log('i ran')
let active
let profile
let menu
let explore
let result 


let assessmentReference = event.target.id


 
  
  explore = document.getElementById('explore-screen')
  active = document.getElementById('active-screen');
  profile =  document.getElementById('fellows-screen');
  menu =  document.getElementById('menu-screen');
  result =  document.getElementById('result-screen');
  
  $(explore).fadeOut('fast',() => {
      $(result).fadeIn('fast', () => { 
       this.retrieveCandidateScore(assessmentReference)
  })
}) 


}

  
  async exploreClose(){
  
  let active
  let explore
  
  active = document.getElementById('active-screen');
  explore =  document.getElementById('explore-screen');
  
  $(explore).fadeOut('fast',() => {
      $(active).fadeIn('fast')
  })



}


async resetProgress(){

  
    const resetPlatform = await fetch('https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/resetProgress',{ 
    method:"POST",
    headers:{
       'content-type': 'application-JSON'
    }, 
    mode:'cors',
    credentials:'include'
    })

    .then(response => response.json())
    .then(response => {
      console.log(response)

      this.setState({
        resetModal: true,
          resetTopicName : response.topicName,
          resetVisual : response.topicVisual,
          resetTopicRef : response.topicRef,
          resetResourceRef: response.resourceRef   
      })
    })
}

async progressUpdate(){ 

 console.log('progress ran')
   const {match :{params}} = this.props;

   console.log(params.resourceRef)
   console.log(params.topicRef)

   const responseReceived = await fetch(`https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/progress-update/${params.topicRef}/${params.resourceRef}/`, {
     method: "POST",
     headers:{
      "content-type" : "application/JSON"
     },
     mode: "cors",
     credentials:"include"
    })

   const  response = await responseReceived.json()

}


//  async timeUpdate (){
  //const currentTime = moment().format('h:mm:ss a')
  //const timeDisplayContainer = document.getElementById('currentTime')
  //timeDisplayContainer.innerHTML= currentTime
   //} 


taskStatusUpdate(status){
    console.log(status)
    this.setState({ 
      taskComplete:status
    })
}

quizAnswerUpdate(answer){
    console.log(answer)
    this.setState({ 
       quizAnswer:answer
    })
}

quizResponseUpdate(answer){
 console.log('on change ran')
  this.setState({
    userAnswer: answer
  })
  console.log(this.state.userAnswer)
}

htmlStateUpdate(data){
    console.log(data)
    this.setState({ 
       codeData: data 
    });
}

byteStateUpdate(taskByteValue){
  console.log(taskByteValue)
   this.setState({ 
       taskByteValue: taskByteValue
  })
}

async activateForwardArrow(){
  this.setState({taskComplete: true })
}

async confirmPortfolioSave(status){
  console.log(status)

  if(status === true )  { 
    this.setState({taskComplete: status },  ToastsStore.success(`Great ${this.state.candidateFirstName}, use the green arrow to continue. You can now load your project in your portfolio`, 
      2000))
   window.responsiveVoice.speak(`Great ${this.state.candidateFirstName}, use the green arrow to continue. You can now load your project in your portfolio`) 
      this.candidateByteIncrease();
  }

  if(status === false) {

    this.setState({taskComplete: status},  ToastsStore.error(`${this.state.candidateFirstName} No, No. make sure your project name filled and image uploaded`, 5000)) 
  
  window.responsiveVoice.speak(`${this.state.candidateFirstName} No, No. make sure your project name is filled and your image is uploaded`) 
    }
}




async switchSkin(){ 
    console.log(' i ran ')  

    const recieveSkinPack = await fetch('https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/skins/',{ 
    method:"GET",
    headers:{
       'content-type': 'application-JSON'
    }, 
    mode:'cors',
    credentials:'include'
    })

    .then(response => response.json())
    .then(response => { 
      this.setState({ 
         skinPack: response
      })
      console.log(this.state.skinPack)
    })

}

async skinUpdate(event){

 const ref = event.target.id

 console.log(ref)
  const data = { skinRef: ref }
  const skinUpdate = await fetch('https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/skins/retrieveSkinLink/', { 
    method:"POST",
    headers:{
      "content-type":"application/JSON"
    },
    body:JSON.stringify(data),
    mode:"cors",
    credentials:'include' 
  })

   .then(response => response.json())
   .then(response => {
    this.setState({ 
        candidateSkin: response[0].skinLink
    })
   })

}

async skinMenu() {

 await this.menuScreen()
 await this.switchSkin()

}


async candidateByteIncrease(){

  console.log(this.state.taskByteValue)
  const data = {earnedByteValue: this.state.taskByteValue}
  const byteUpdate = await fetch('https://atcbrain.herokuapp.com/learning-engine/response-api/v1/updateByteCount/increase/', { 
    method:"POST",
    headers:{
      "content-type":"application/JSON"
    },
    body:JSON.stringify(data),
    mode:"cors",
    credentials:'include' 
  })

  if(!byteUpdate){
   return console.log('server did not respond with byte')
  }

  const byteUpdateResponse = byteUpdate.json()
  console.log(byteUpdateResponse)

}


async retrieveOnlineFellowsBySchool(){ 

     console.log('get candidates ran')
   const retrievedOnlineFellows = await fetch('https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/get-online-candidates/',{ 
   method:"POST",
   headers:{
    "content-type" : "application-JSON"
   },
   mode:"cors",
   credentials:"include"
   })

   .then(response => response.json())
   .then(response => { 
      this.setState({ 
          onlineCandidates : response
      })
   })

} 


async profileMenu(){

 console.log('profile ran')
  await this.profileScreen()
  await this.retrieveOnlineFellowsBySchool()

}


async getAssessmentReferences() {

  const classAssessmentFilterParameters = { 
       classYear: this.state.candidateClass,
       schoolid: this.state.candidateSchoolid
  }
    
    console.log(classAssessmentFilterParameters)


const candidate = await fetch('https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/skins/assessmentRef',{ 

method:"POST",
headers:{ 
"content-type" : "application/json"
},
body: JSON.stringify(classAssessmentFilterParameters),
mode:"cors",
credentials:"include"

})
  .then(response => response.json())
  .then(response => { 
      console.log(response)
      this.setState({  
       currentAssessmentTaskRef: response[0].taskRef,
       currentAssessmentTopicRef: response[0].topicRef,
       currentAssessmentRef: response[0].assessmentRef
      })
  })
}


async setAssessmentStatus() { 
  console.log('ranning1')
  console.log(this.state.candidateAssessmentStatus)

  if(!this.state.candidateAssessmentStatus){

         $("#taskButton").addClass('disabled taskButton')

         
       
  }

  if(this.state.candidateAssessmentStatus){ 
console.log('ranning')
    await this.getAssessmentReferences()
    
     this.setState({ 
        assessmentLink: `/lab/${this.state.currentAssessmentTopicRef}/assessments/${this.state.currentAssessmentTaskRef}/category/${this.state.currentAssessmentRef}`
     }, ()=>{ 

         if($("#taskButton").hasClass('disabled taskButton')){
          $("#taskButton").removeClass('disabled taskButton')
         }
     })
       
  }

}

async editStudentProfile(){ 
$('#imageUpload').click(async function(e){ 

    console.log('uploadRan')

  e.preventDefault()
   // Configure Cloudinary
   // with the credentials on
   // your Cloudinary dashboard
   await  $.cloudinary.config({ cloud_name: 'code4teen-tech', api_key: '373254287764286'});     
   // Initiate upload
   window.cloudinary.openUploadWidget({ cloud_name: 'code4teen-tech', upload_preset: 'candidateImage', tags: ['cgal']}, 
   async function(error, result) { 
   if(error) console.log(error);
 
   if(result){    
     console.log(result) 
     //data sent in request body 
        let avatar = {
        avatar: result[0].secure_url
          }

      

        let updateAvatar = await fetch('https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/updateAvatar',{ 
          method: "POST",
          body: JSON.stringify(avatar),
          headers:{
            'Content-Type':'application/JSON',
            'Accept': 'application/JSON'
          },
          mode:'cors',
          credentials:'include'  
        })
      
        .then(response => response.json())
        .then(response => { 
        
          this.setState({ 
            candidateAvatar : response.avatar[0]
          })
        })


   }
     //console.log(result);
   
 
 }.bind(this))



  }.bind(this))
}


async candidateByteDecrease(){


  this.setState({

    topicStatus:true,

  })

console.log("i ran")
const data = {ref: this.state.prevResourceRef}

const byteUpdate = await fetch('https://atcbrain.herokuapp.com/learning-engine/response-api/v1/updateByteCount/decrease/', { 
  method:"POST",
  headers:{
    "content-type":"application/JSON" 
  },
  body:JSON.stringify(data),
  mode:"cors",
  credentials:'include'
  })

if(!byteUpdate){
  return console.log('server did not respond with byte')
}

const byteUpdateResponse = byteUpdate.json()
console.log(byteUpdateResponse)
}


async saveEditorResponse(){

  const {match :{params}} = this.props;

  const data = {
    response:this.state.codeData,
    ref: params.resourceRef
   }

  const saveResponse = await fetch('https://atcbrain.herokuapp.com/learning-engine/response-api/v1/saveEditorResponse/',{ 
    method: "POST",
    body: JSON.stringify(data),
    headers:{
      'Content-Type':'application/JSON',
      'Accept': 'application/JSON'
    },
    mode:'cors',
    credentials:'include'  
  })

  .then(response => response.json())
  .then(response => console.log('saved editor response'))
}


 
async validateResponse(e) { 

  this.saveEditorResponse()
  const { match: { params } } = this.props;
      e.preventDefault();
      console.log('button Click')      
      console.log(this.state.codeData)
          
         const data = {html:this.state.codeData}
         console.log(data)
          const validationResponse = await fetch(`https://atcbrain.herokuapp.com/evaluation-engine/validation-api/v1/${params.resourceRef}/`, {
            method:"POST",
            body:JSON.stringify(data),
            headers:{
               'Content-Type':'application/JSON',
               'Accept': 'application/json'
             },   
            mode: 'cors',
            credentials: 'include'
          })
          .then(response => response.json())
          .then(response => { console.log(response)
              if(response === true )  { 
                this.setState({taskComplete: true },  ToastsStore.success(`Great Job ${this.state.candidateFirstName}, use the green arrow to continue`, 
                  2000))
               window.responsiveVoice.speak(`Great Job ${this.state.candidateFirstName}, use the green arrow to continue`) 
                  this.candidateByteIncrease();
              }

              if(response === false) {

                this.setState({taskComplete: false},  ToastsStore.error(`${this.state.candidateFirstName} No, No. Check your code`, 5000)) 
              
              window.responsiveVoice.speak(`${this.state.candidateFirstName} No, No. Check your code`) 
                }
           })          
  }

async validateQuizResponse(e){ 

  e.preventDefault();
   console.log('button click')

  console.log(this.state.userAnswer)
  console.log(this.state.quizAnswer)
 if(this.state.userAnswer == this.state.quizAnswer){
        this.setState({
            taskComplete:true
        }, ToastsStore.success(`Thats the right answer ${this.state.candidateFirstName}! use the green arrow to continue`,
       2000))

 window.responsiveVoice.speak(`That's the right answer ${this.state.candidateFirstName}! use the green arrow to continue`) 
      this.candidateByteIncrease();

 }else{
        this.setState({
            taskComplete:false
        }, ToastsStore.error(`That's incorrect ${this.state.candidateFirstName}. Check your answer`, 3000))

        window.responsiveVoice.speak(`That's incorrect ${this.state.candidateFirstName}. Check your answer`) 
 


  }  
}



async validateAnalysisQuizResponse(){ 

  
   console.log('analysis quiz')

  console.log(this.state.userAnswer)
  console.log(this.state.quizAnswer)
 if(this.state.userAnswer == this.state.quizAnswer){
        this.setState({
            taskComplete:true
        }, ToastsStore.success(`Thats the right answer ${this.state.candidateFirstName}! use the green arrow to continue`,
       2000))

 window.responsiveVoice.speak(`That's the right answer ${this.state.candidateFirstName}! use the green arrow to continue`) 
      this.candidateByteIncrease();

 }else{
        this.setState({
            taskComplete:false
        }, ToastsStore.error(`That's incorrect ${this.state.candidateFirstName}. Check your answer`, 3000))

        window.responsiveVoice.speak(`That's incorrect ${this.state.candidateFirstName}. Check your answer`) 
 


  }  
}



async retrieveCandidateDetails(){ 


    const candidate = await fetch('https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/candidate/',{ 
      method:"POST",
      headers:{ 
        "content-type" : "application/json"
       },
     mode:"cors",
     credentials:"include"
    })
    .then(response => response.json())
    .then(response => { 
       console.log(response)
       if(!response){
        window.location.href = "/"
      }
      this.setState({
      candidateFirstName:response.candidateFirstname,
      candidateFullname : response.candidateFirstname + " " + response.lastname,
      candidateSkin:response.skin,
      candidateAvatar: response.avatar[0],
      candidateClass : response.class,
      candidateTopicName: response.topicName,
      candidateTopicDescription: response.topicDescription,
      candidateTopicVisual: response.topicVisual,
      candidateAssessmentStatus : response.assessmentStatus,
      candidateAge : response.age,
      candidateSchool: response.school,
      candidateSchoolid : response.schoolid,
      schoollogo: response.logo,
      assessmentRef:response.assessmentReference
     })
    }

    )

}


async retrieveResourceDetails(){ 

       const {match:{ params }} = this.props

       const resourceRef = await fetch(`https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/${params.resourceRef}/forward-switch-resource/${params.topicRef}/`, { 
         method:"POST",
         mode:"cors", 
         headers:{ 
            "content-type" : "application-JSON" 
         },
         credentials:"include"
       })
   const resourceRefData = await resourceRef.json()
   
    console.log(resourceRefData)


      if(!resourceRefData.topicStatus.status){

         this.setState({ 
       resourceRef : resourceRefData.nextRef.ref,
       prevResourceRef: resourceRefData.prevRef.ref,
       topicRef: resourceRefData.nextTopic.ref,
       prevTopicRef: resourceRefData.prevTopic.ref,
       topicStatus: resourceRefData.topicStatus.status
            })

    }


    if(resourceRefData.topicStatus.status){

       this.setState({ 
       resourceRef : resourceRefData.nextRef.ref,
       prevResourceRef: resourceRefData.prevRef.ref,
       topicRef: resourceRefData.nextTopic.ref,
       prevTopicRef: resourceRefData.prevTopic.ref,
       topicStatus: resourceRefData.topicStatus.status

     })

    }

   console.log(resourceRefData.topicStatus)
   console.log(this.state.topicStatus)

 }

 async retreiveResourceIconPack(){ 

   console.log("fired")
    const {match:{params}} = this.props

    const iconPack = await fetch(`https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/${params.topicRef}/retrieveIconPack/`, { 
       method:"POST",
       headers:{
        "Content-Type" : "application/json"
       },
       mode:"cors",
       credentials:"include"     
    })   

    const  recievedIconPack = await iconPack.json()

    console.log(recievedIconPack)

    this.setState({ 
     iconPack : recievedIconPack
    })

 }


loaderOpen(){

  document.getElementById('load-screen').style.display = "block"
  document.getElementById('active-screen').style.visibility="hidden"

} 


loaderClose(){

  document.getElementById('load-screen').style.display = "none"
   document.getElementById('active-screen').style.visibility="visible"


} 

render(){ 


 let  newLabProgress = `/lab/${this.state.resetTopicRef}/lessons/${this.state.resetResourceRef}`
 
  setInterval(this.timeUpdate, 1000)
 


 //handle null and active button display with boolean state
    const {match: { params } } = this.props
    console.log(params.resourceRef)
    console.log(params.topicRef)
    let backwardSwitch 
    let forwardSwitch


          if(this.state.prevResourceRef === false){ 
            backwardSwitch = <img src={backwardSwitchButtonDisabled} width="40"/>
          }else{
         backwardSwitch = < BackwardSwitchButton prevTopicRef={this.state.prevTopicRef}  prevResourceRef={this.state.prevResourceRef} byteDecrease={this.candidateByteDecrease}/>
           }
         if (this.state.taskComplete === false || this.state.topicStatus === false){
           forwardSwitch = <img src={switchButtonDisabled} width="40"/> 
            }else{
           forwardSwitch = <SwitchButton topicRef={this.state.topicRef} resourceRef={this.state.resourceRef} currentProgress={this.progressUpdate}  />
            }

          
      let iconPackDetails
      let skinPackDetails
      let assessmentRefDetails
      let newRef 
      let onlineFellows

      if(this.state.assessmentRef.length){
        console.log(this.state.assessmentRef)

        assessmentRefDetails = this.state.assessmentRef.map( assessmentDetails => {
          if(!assessmentDetails.result){
           return <div className="row">
            <div className="col-6"> 
                  <h6 className="body-font white-text"> {assessmentDetails.topic} </h6>
            </div>
  
            <div className="col-6 body-font"> 
                   <button className="btn btn-outline-success disabled taskButton" id={assessmentDetails.ref}> See results </button>
                   </div>
  
          </div>
          }

          if(assessmentDetails.result){
             console.log('built')
            return <div className="row">
             <div className="col-6"> 
                   <h6 className="body-font white-text"> {assessmentDetails.topic} </h6>
             </div>
   
             <div className="col-6 body-font"> 
                    <button className="btn btn-outline-success results" onClick={this.resultScreen} id={assessmentDetails.ref}> See results </button>
                    </div>
   
           </div>
           }
   
        }
         
                
          
        
    

        )
      }

      if(this.state.iconPack.length){ 
        console.log(this.state.iconPack)
             iconPackDetails = this.state.iconPack.map(iconDetails => 

          <li className="standard-padding-tb text-center"> 
             <figure> 
             <img className="img-fluid" src={iconDetails.resourceIcon} width="80px"/>
             <figcaption className='header-font white-text mobile-text-size'>
             {iconDetails.ref}
             </figcaption> 
             </figure> 
             </li>
       )
         
      }

           if(this.state.skinPack.length){ 
     
          skinPackDetails = this.state.skinPack.map(skinDetails => 
          
           
          <div className="col-md-4 text-center"> 
             <figure> 
             <img src={skinDetails.skinLink} onClick={this.skinUpdate} value={skinDetails.ref} id={skinDetails.ref} width="250px"/>
             <figcaption className='header-font white-text'>
             {skinDetails.ref}
             </figcaption> 
             </figure> 
             </div>
       )
         
      }


      if(this.state.onlineCandidates.length){ 

          onlineFellows = this.state.onlineCandidates.map(candidateDetails => 

            <div className="col-md-2 text-center"> 
             <figure> 
             <img src={candidateDetails.avatar} width="150px"/>
             <figcaption className='header-font white-text'>
             {candidateDetails.firstname}
             </figcaption> 
             </figure> 
             </div>

          )

      }

   
 return(
  <div >

  
  <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="horizontal-screen"> 

<div className="row left-side-curve top-right-curve bottom-right-curve"  id="labBoxStyle"> 

 <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background text-center" > 
         <figure className="text-center standard-padding-tb nav-clear-space-screen"> 
        <img className="standard-margin-top-quiz" src={atclogo} width='200' onClick={this.rotateScreen}/>   
         <figcaption>
             <h4 className="header-font text-center white-text"> Tap me ! </h4>
         </figcaption>
        </figure>
  </div>
</div>

 </div>

      <div className="" id='labs'>

        <header> 
         <Nav menuScreen={this.skinMenu} profileMenu={this.profileMenu} exploreScreen={this.exploreScreen}  />
        </header>
         <div className="nav-clear-space-screen" >
           <div id="full-width" className="skin-actual full-width">
                 <img src={this.state.candidateSkin} width="100%" height="100%"  />
           </div>




           <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="explore-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve"  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 
               
          <div className="row standard-padding-top-1 standard-margin-top fixed-top">
          <div className="col-6 col-md-5 offset-md-1">
          <img id="" src={this.state.schoollogo} width="70"/>
           </div> 
           <div className="col-5">
             <h5 className="header-font text-right white-text"> Close Explore <span> <img src={forwardSwitchButton} onClick={this.exploreClose} width='35' /> </span> </h5>
           </div> 
           </div>
           <div className="row standard-padding-top-1">
           <div className="col-12 text-center">
                <img id="candidateImage" src={this.state.candidateAvatar} width="100"/>
             </div>

             <div className="col-12 text-center"> 
             <button className="btn btn-danger" id="imageUpload"> Change Photo </button>
             </div>

             <div className="col-12 col-md-4 mx-auto"> 
                <div className="row text-center"> 
                 <div className="col-12"> 
                    <h3 className="accent-font white-text"> {this.state.candidateFullname} </h3>
                  </div>
                  <div className="col-12 col-md-4"> 
                    <h6 className="body-font white-text"> Age:{this.state.candidateAge} </h6>
                  </div>

                  <div className="col-12 col-md-4"> 
              <h6 className="body-font white-text"> Grade:{this.state.candidateClass} </h6>
                  </div>

                  <div className="col-12 col-md-4"> 
                  <Link to={this.state.assessmentLink}>
              <button className="btn btn-outline-danger" id="taskButton"> Pending Task </button>
              </Link>
                  </div>

                </div>

      <hr className="white-border full-width" />

             </div> 




          </div> 
    <div className="row standard-margin-top">
             <div className="col-12 col-md-4 offset-md-1 text-center">
            <h6 className="accent-font white-text"> You are currently learning </h6>
            <img src={this.state.candidateTopicVisual} width="100%"/>
            <h4 className="header-font white-text"> {this.state.candidateTopicName} </h4>
            <h6 className="body-font white-text"> {this.state.candidateTopicDescription} </h6>
            <div className="col-12">
             <button className="btn btn-outline-danger resetProgress" onClick={this.resetProgress}> Reset Progress </button>
             </div>
             </div>
           
             <div className="col-12 offset-md-1 col-md-6"> 
          <h6 className="accent-font white-text"> Your assessments for {this.state.candidateTopicName} </h6>
             {assessmentRefDetails}



             </div>
           


               
             </div>

        </div>

        </div>
    
       </div>
            
       <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="result-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve"  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 
               
          <div className="row">
           <div className="col-12 standard-padding-tb"> 
            <h2 className="white-text accent-font text-center"> Here's how you performed {this.state.candidateFirstName} </h2>
           </div> 
           <div className="col-12 col-md-5 mx-auto text-center">
         <h4 className="body-font white-text"> {this.state.candidateTopicName} - {this.state.newAssessmentRef} </h4>
         </div>
         <div className="col-10 mx-auto">
         <img src={this.state.candidateTopicVisual} width="100%"/>
         </div>
       
         <div className="col-12 col-md-4 text-center">

           <h4 className="accent-font white-text"> Assessment Score</h4>
           <h1 className="body-font white-text">  
             {this.state.assessmentGrade}
                     
           </h1>

         </div>

         <div className="col-12 col-md-4 text-center">

           <h4 className="accent-font white-text"> Current Topic Score</h4>
           <h1 className="body-font white-text"> 
             {this.state.currentTopicGrade}
            </h1>

         </div>

         <div className="col-12 col-md-4 text-center">

            <h4 className="accent-font white-text"> Total Topic Score</h4>
            <h1 className="body-font white-text"> 
              {this.state.totalTopicGrade}
             </h1>

         </div>

           <div className="col-12">
             <h5 className="header-font text-right white-text">  <span> <img src={forwardSwitchButton} onClick={this.resultClose} width='35' /> </span> back to explore </h5>
           </div> 

          </div> 
    

        </div>

        </div>
    
       </div>



            <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="fellows-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve"  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 
               
          <div className="row">
           <div className="col-12 standard-padding-tb"> 
            <h2 className="white-text accent-font text-center"> See whose coding in your class </h2>
           </div> 
           {onlineFellows}
           <div className="col-12">
             <h5 className="header-font text-right white-text"> close skin menu <span> <img src={forwardSwitchButton} onClick={this.profileClose} width='35' /> </span> </h5>
           </div> 

          </div> 
    

        </div>

        </div>
    
       </div>

            <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="menu-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve"  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 
               
          <div className="row">
           <div className="col-12 standard-padding-tb"> 
            <h2 className="white-text accent-font text-center"> Hello, Select a skin for your tablet </h2>
           </div> 
           {skinPackDetails}
           <div className="col-12">
             <h5 className="header-font text-right white-text"> save and close skin menu <span> <img src={forwardSwitchButton} onClick={this.menuClose} width='35' /> </span> </h5>
           </div> 

          </div> 
    

        </div>

        </div>
    
       </div>


                     <div className="container top-right-curve bottom-right-curve skin padding-off" id="sleep-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve"  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 

          <figure className="text-center standard-padding-tb nav-clear-space-screen"> 
              <h6 className="body-font text-center white-text" id="currentTime">  </h6>
              <img className="standard-margin-top-quiz" src={atclogo} width='200' />
              <figcaption className="standard-margin-top-quiz">
                 <h5 className="header-font text-center white-text"> Wake Andrew Up <span> <img src={forwardSwitchButton} onClick={this.wakeScreen} width='35' /> </span> </h5>
              </figcaption> 
          </figure>
    

        </div>

        </div>
    
       </div>



       <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="load-screen"> 

      <div className="row left-side-curve top-right-curve bottom-right-curve"  id="labBoxStyle"> 

       <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background text-center" > 
               <figure className="text-center standard-padding-tb nav-clear-space-screen"> 
              <img className="standard-margin-top-quiz" src={atclogo} width='200' />   
               <figcaption>
                   <h5 className="header-font text-center white-text"> One second please </h5>
               </figcaption>
              </figure>
        </div>
      </div>

       </div>


       


           <div className="app-screen-margin top-right-curve bottom-right-curve skin padding-off" id="active-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve" id="labBoxStyleClass"> 
        <div className="col-md-2 blue-background-2 top-left-curve bottom-left-curve" id="sidebar-properties"> 
         <div className="row"> 
         <div className="col-12 green-background top-left-curve standard-padding-tb sub-menu-style"> 
                 <p className="white-text text-center margin-off body-font mobile-text-size"> We are learning </p> 
         </div>
          <div className="col-12 height-test-minus sub-menu-layout standard-padding-tb ">
           <ul className="padding-off"> 
            {iconPackDetails}
         </ul>
           
          </div>
          <div className="col-12 standard-padding-tb text-center green-background"> 

              {backwardSwitch}
              {forwardSwitch}  
          </div>
         </div>    
           <p className="accent-font white-text text-center mobile-text-size"> {this.state.candidateFirstName}'s booklet </p>     
        </div>

        <div className="col-md-10 padding-off white-background" id="explanation"> 

        {this.props.children}
         <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} timer={10000}/>
        <Route path={`/lab/:topicRef/lessons/:resourceRef`} render={ (props) => <TheWeb {...props} loaderClose={this.loaderClose} loaderOpen={this.loaderOpen} dynamicWidth={this.state.width} dynamicHeight={this.state.height} byteStateValue={this.byteStateUpdate} onHtmlChange={this.htmlStateUpdate} validateResponse={this.validateResponse} validateQuizResponse={this.validateQuizResponse} validateAnalysisQuizResponse={this.validateAnalysisQuizResponse} quizAnswerUpdate={this.quizAnswerUpdate} quizResponseUpdate={this.quizResponseUpdate} progressUpdate={this.progressUpdate} taskStatusUpdate={this.taskStatusUpdate} confirmPortfolioSave={this.confirmPortfolioSave} activateForwardArrow={this.activateForwardArrow} /> } />
       



      <Modal className="white-background-trans" show={this.state.resetModal} animation={false}>
           <Modal.Header>
          <Modal.Title> Your book has been successfully reset </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padding-off">
          <div className="row">
            <div className="col-12">
             Great, you are now going back to chapter one of Andrew Teaches Coding- Term A 
             </div> 

             <div className="col-12">
               <figure>
             <img src={this.state.resetVisual} width="100%" />
               <figcaption> 
                    <h4 className="header-font"> {this.state.resetTopicName} </h4>
               </figcaption>
             </figure>
             </div> 

             <div className="col-12 text-center"> 

             <Link to={newLabProgress}>
                   <button onClick={()=> this.setState({resetModal:false})} className="btn btn-success"> Continue </button>
             </Link> 
             </div>
             </div>
      

          </Modal.Body>
      </Modal>

    

        </div>

        </div>
    
      </div>
       </div>
     


         <div className="row" id="alert" style={{display: "none"}}>
            <div className="col-12 mt-5">
                <div className="alert alert-danger text-center" role="alert">
                  <h4 className="alert-heading alertMsg" id="alertTitle"></h4>
                  <img src="rotate.gif" alt="Rotate gif" className="img-fluid d-none" id="gif" />
                  <p id="alertBody"></p>
                </div>
            </div>
        </div>
       </div>
       </div>

     	)   
 }
}


export default lab;
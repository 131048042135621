import React, { Component } from 'react';
import cloudinary from 'cloudinary-jquery-file-upload/cloudinary-jquery-file-upload.js'
import jquery from 'jquery/dist/jquery.js';
import blueImpWidget from 'blueimp-file-upload/js/vendor/jquery.ui.widget.js'
import blueImpTransport from 'blueimp-file-upload/js/jquery.iframe-transport.js'
import blueImpUpload from 'blueimp-file-upload/js/jquery.fileupload.js'




class uploads extends Component { 

     constructor(props){
       super(props)
       this.state={
       cloudImage: this.props.visualA,
       apiUrl:`https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/${this.props.resourceRef}/upload-image-to-cloud`
       }

       this.Imageupload = this.Imageupload.bind(this)
     }


   async componentDidUpdate(){
      
   }

   async componentDidMount(){

      this.props.taskStatus(false)

   }

    
  async Imageupload(e) {

  	e.preventDefault()
    // Configure Cloudinary
    // with the credentials on
    // your Cloudinary dashboard
  await  jquery.cloudinary.config({ cloud_name: 'www-code4teen-tech', api_key: '858627157322821'});     
        // Initiate upload
        window.cloudinary.openUploadWidget({ cloud_name: 'www-code4teen-tech', upload_preset: 'csizcotf', tags: ['cgal']}, 
        function(error, result) { 
            if(error) console.log(error);
            // If NO error, log image data to console
            if(result){
         	this.setState({
            		cloudImage:result[0].secure_url
            	})
         	this.props.taskStatus(true)
            //console.log(result);
        }
        }.bind(this));

}


   render(){

   	return(

      	<div className="container top-right-curve bottom-right-curve container-pad">

          <div className="row standard-padding-tb-3  top-right-curve "> 
          <div className="col-12 text-center standard-padding-tb-3">
          <h4 className="header-font black-text"> 
            {this.props.heading}
          </h4>
          </div>
          

           <div className="col-md-4">

           <img src={this.state.cloudImage} width='330' height="315" />

           </div>

            <div className="col-md-8">

            <p className="header-font blue-text">
                 {this.props.explanationA}
            </p>


         
          <div className="col-12 standard-padding-tb-3">
            <button id="" className="btn btn-outline-success" onClick={this.Imageupload} > Upload a picture and recieve a URl below </button>
            </div>
          

           
           </div>
     
          </div> 

  <div className="row bottom-right-curve black-background"> 


 <div className="col-12 padding-off bottom-right-curve text-center standard-padding-tb-3"> 
  <figure> 
           <h5 className="header-font blue-text"> Your URL is here </h5>
           <figcaption className="body-font blue-text"> 
               <h6>  {this.state.cloudImage} </h6>
           </figcaption>
           </figure>
  </div>


 </div>
          

          </div>
)




   }


}


export default uploads
import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import TheWeb from '../learningmodules/theweb';
import loader from '../../images/loader.png';
import Editor  from '../editors/playground';
import Upload  from '../upload';
import Uploader from '../editors/upload-pg.js';
import QuizA from '../quiz-dir/who-am-i-assessments';
import QuizB from '../quiz-dir/who-am-i-assessments-2';
import EditorQuizA from '../quiz-dir/editorQuiz-assessments-1';
import EditorQuizB from '../quiz-dir/editorQuiz-assessments-2';
import EditorA from '../editors/playground-assessments-1';
import EditorB from '../editors/playground-assessments-2';
import Submission from '../task-completion'


 class assessments extends Component {

        constructor(props){
        	super(props);

        	this.state={
           assessmentRef:"",
           taskRef:"", 
           tag:"",
           question:"",
           optionA:"",
           optionB:"",
           optionC:"",
           visual:"",
           codeSnippet:"",
           taskDescription:"",
           taskA:"",
           taskB:"",
           taskC:"",
           taskD:"",
           taskE:"",
           taskF:"",
           taskG:"",
        	}
              this.fetchQuizResource = this.fetchQuizResource.bind(this);

        }


    async componentWillMount(){ 
      console.log('will mount')
    } 


    async componentDidMount(){ 
       const { match:{ url, params }} = this.props; 
        console.log(params)
        await this.fetchQuizResource()
    }

   // async shouldComponentUpdate(prevProps, prevState){
    //	const {match:{ url, params }} = this.props;
     //   console.log(this.state.ref)
      //  console.log(prevState.ref)
    //}

    async componentWillReceiveProps(nextProps){
       const {match:{ url, params }} = this.props;              
    }

   async componentDidUpdate(prevProps){
    const {match:{ url, params }} = this.props;
    console.log(params.taskRef)
    console.log(prevProps.match.params.taskRef)

      if (prevProps.match.params.taskRef != params.taskRef){   
      await this.fetchQuizResource()     
    }

   	   // console.log(params.resourceRef)
   	   // console.log(prevProps.match.params.resourceRef)

 // if(params.resourceRef !=  && params.resourceRef ){
   //     await this.fetchResource();
         //console.log('receive props')
   
     //  }
     }


     async fetchQuizResource(){

       const { match: { params } } = this.props;

         console.log(params)
      const requestResource = await fetch(`https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/${params.taskRef}/quizAssessmentTask`, {
      method:"POST", 
      headers:{
       "Content-Type" : "application-json"
      },
      mode:"cors"
     })
     .then(response => response.json())
     .then(response => {console.log(response)


     if(response.type === false) {
       this.setState({ 
                  question:response.task[0].question,
                  optionA: response.task[0].optionA,
                  optionB: response.task[0].optionB,
                  optionC: response.task[0].optionC, 
                  visual:  response.task[0].visual,
                  tag: response.task[0].tag,
                  assessmentRef: response.task[0].assessmentRef,
                  codeSnippet: response.task[0].codeSnippet              
            })

     }else{

      this.setState({ 
          codeSnippet:response.task[0].codeSnippet,
          taskDescription:response.task[0].taskDescription,
          taskA:response.task[0].taskA,
          taskB:response.task[0].taskB,
          taskC:response.task[0].taskC,
          taskD:response.task[0].taskD,
          taskE:response.task[0].taskE,
          taskF:response.task[0].taskF,
          taskG:response.task[0].taskG,
          tag: response.task[0].tag,
          assessmentRef: response.task[0].assessmentRef  
      })



       console.log(this.state.codeSnippet)
     }

     })
}


    render(){

      const { match: { params } } = this.props;

      console.log('i ran')
      console.log(this.props)
    //console.log(this.state.ref)
    console.log(params.taskRef)

    //check state has been updated with new values, if not return loader

    	//if(this.state.taskRef != params.taskRef){ 
      //		return <div className="me"> </div> 
      //	}

    //return canvas element if state has been updated with new values

      return(


      	<div className="you">
         
      	{ (() => {
      		//console.log('i ran')
       switch(params.taskRef){  
        case 'the-web':
           return <QuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
        case 'the-internet':
           return <QuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
        case 'text-publishing':
           return <QuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
        case 'html-designing':
           return <QuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
        case 'tag-identification-a':
           return <QuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
        case 'tag-identification-b':
           return <QuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
        case 'tag-identification-c':
           return <QuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
        case 'tag-identification-d':
           return <QuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
        case 'tag-identification-e':
           return <QuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
        case 'tag-identification-f':
           return <QuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual}  quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
        case 'tag-identification-g':
           return <QuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual}  quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;      
        case 'editor-task-1':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-2':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-3':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-4':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-5':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-6':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-7':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-8':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-9':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-10':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-11':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-12':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-13':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-14':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-15':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-16':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-17':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-18':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-19':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-20':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-21':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-22':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-23':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-24':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-25':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-26':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-27':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-28':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-29':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
        case 'editor-task-30':
           return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>; 
        case 'submit-assessment':
           return <Submission taskRef={params.taskRef}  />;
           case 'the-web-exam':
            return <QuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
         case 'the-internet-exam':
            return <QuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
         case 'text-publishing-exam':
            return <QuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
         case 'html-designing-exam':
            return <QuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
         case 'tag-identification-a-exam':
            return <QuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
         case 'tag-identification-b-exam':
            return <QuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
         case 'tag-identification-c-exam':
            return <QuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
         case 'tag-identification-d-exam':
            return <QuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
         case 'tag-identification-e-exam':
            return <QuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
         case 'tag-identification-f-exam':
            return <QuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual}  quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;
         case 'tag-identification-g-exam':
            return <QuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual}  quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} />;      
         case 'editor-task-2-exam':
            return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-3-exam':
            return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-5-exam':
            return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-6-exam':
            return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-7-exam':
            return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-14-exam':
            return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-15-exam':
            return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-20-exam':
            return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-aa':
            return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-ab':
            return <EditorB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'tag-positions-aa':
            return <EditorQuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} />;
         case 'tag-positions-ab':
            return <EditorQuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet}  />;
         case 'tag-positions-ac':
            return <EditorQuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} />;
         case 'tag-positions-ad':
            return <EditorQuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet}  />;
         case 'tag-positions-ae':
               return <EditorQuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} />;
         case 'tag-positions-af':
               return <EditorQuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet}  />;
         case 'editor-task-ac':
               return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-ad':
               return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-ae':
               return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;             
         case 'editor-task-af':
                  return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;                
         case 'editor-task-ag':
                     return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;                      
         case 'editor-task-ah':
            return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-ai':
            return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-aj':
            return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-ak':
               return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-al':
               return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;
         case 'editor-task-am':
               return <EditorA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} codeSnippet={this.state.codeSnippet} taskDescription={this.state.taskDescription} taskA={this.state.taskA} taskB={this.state.taskB} taskC={this.state.taskC} taskD={this.state.taskD} taskE={this.state.taskE} taskF={this.state.taskF} taskG={this.state.taskG} tag={this.state.tag}  quizResponseUpdate={this.props.quizOnChange}/>;               
         case 'page-organisation-a':
               return <EditorQuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} />;
         case 'page-organisation-b':
               return <EditorQuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet}  />;
         case 'page-organisation-c':
               return <EditorQuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} />;
         case 'page-organisatiom-d':
               return <EditorQuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet}  />;
         case 'page-organisation-e':
                  return <EditorQuizA height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet} />;
         case 'page-organisation-f':
                  return <EditorQuizB height={this.props.dynamicHeight} width={this.props.dynamicWidth} taskRef={params.taskRef} assessmentRef={this.state.assessmentRef} question={this.state.question} optionA={this.state.optionA} optionB={this.state.optionB} optionC={this.state.optionC} visual={this.state.visual} quizResponseUpdate={this.props.quizOnChange} tag={this.state.tag} codeSnippet={this.state.codeSnippet}  />;
                                                                                                                                                                                                  
            
       }
      	})()}


      	

      
       </div>

    	)	

    }
  


}

export default assessments